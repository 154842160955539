// Drag to scroll
// Bootstrap Accordion
// import { Collapse } from 'bootstrap';

function dragtoscroll(element) {
  const ele = element;
  ele.style.cursor = 'grab';

  let pos = {
    top: 0, left: 0, x: 0, y: 0,
  };

  const mouseMoveHandler = (e) => {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = () => {
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = (e) => {
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';

    pos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  // Attach the handler
  ele.addEventListener('mousedown', mouseDownHandler);
}

function setCookie(cname, cvalue, days = 30) {
  const d = new Date();
  d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

/**
 * Set Cookie if marketing is accpteted
 */
function cookieConsentHandling() {
  if (typeof window.CookieBot !== 'undefined') {
    // Set Cookie
    window.addEventListener('CookiebotOnAccept', () => {
      if (window.CookieBot.consent.marketing) {
        setCookie('marketing_accepted', true, 356);
      }
    }, false);

    // Delete Cookie
    window.addEventListener('CookiebotOnDecline', () => {
      if (!window.CookieBot.consent.marketing) {
        setCookie('marketing_accepted', null, -100);
      }
    }, false);
  }

  if (typeof window.UC_UI !== 'undefined') {
    window.addEventListener('ucEvent', (e) => {
      if (e.detail && e.detail.event === 'consent_status') {
        // Check for Google Ads
        // We don't get info for the string "marketing", but that one is a marketing Cookie
        if (e.detail['Google Ads'] === true) {
          // Set Cookie
          setCookie('marketing_accepted', true, 356);
        } else {
          // Delete Cookie
          setCookie('marketing_accepted', null, -100);
        }
      }
    });
  }
}

window.addEventListener('load', () => {
  // Drag to Scroll
  const elementsToScroll = document.querySelectorAll('.scrolled');
  let i;
  for (i = 0; i < elementsToScroll.length; i += 1) {
    dragtoscroll(elementsToScroll[i]);
  }

  // Bootstrap Accordion
  /*  const accordions = document.querySelectorAll('.accordion');
  accordions.forEach((accordion) => {
    const collapse = new Collapse(accordion);
    collapse.hide();
  }); */

  cookieConsentHandling();
});
